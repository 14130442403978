import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
	initialColorMode: "dark",
	useSystemColorMode: false,
};

const components = {};
// #20E08D
const theme = extendTheme({
	config,
	fonts: {
		body: "Varela",
	},
	colors: {
		light: {
			50: "#ffffff",
			100: "#cbceeb",
			200: "#a9aed6",
			300: "#888ec5",
			400: "#666db3",
			500: "#4d5499",
			600: "#4d5499", // this one
			700: "#2a2f57",
			800: "#181c37",
			900: "#080819",
		},
		dark: {
			50: "#1a202c", // #151a25
			100: "#cbceeb",
			200: "#62f59a",
			300: "#62f59a",
			400: "#62f59a",
			500: "#62f59a",
			600: "#62f59a",
			700: "#62f59a",
			800: "#62f59a",
			900: "#62f59a", //#38e098
		},
		red: {
			200: "#FC8181",
		},
	},
	components: {
		Button: {
			baseStyle: {
				fontWeight: "bold", // Normally, it is "semibold"
			},
		},
	},
	styles: {
		global: () => ({
			body: {
				color: "white",
				bg: "dark.50",
				lineHeight: "base",
			},
		}),
	},
});
export default theme;
