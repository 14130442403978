import {
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { addDoc, collection } from "firebase/firestore/lite";
import { Field, Form, Formik } from "formik";
import lottie from "lottie-web";
import React, { createRef, LegacyRef, useEffect } from "react";
import animation from "./assets/checkmark.json";
import { db } from "./utils/firebase";

const validate = (value: string) => {
	let errorMessage;
	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		errorMessage = "Invalid email address";
	}
	return errorMessage;
};

function App() {
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const toast = useToast();
	const animationContainer: LegacyRef<HTMLDivElement> = createRef();

	useEffect(() => {
		const anim = lottie.loadAnimation({
			//@ts-ignore
			container: animationContainer.current,
			renderer: "svg",
			loop: false,
			autoplay: true,
			animationData: animation,
		});
		return () => anim.destroy(); // optional clean up for unmounting
	}, [isSubmitted]);

	return (
		<Flex h='100vh' alignItems='center' justifyContent='center'>
			<VStack maxW='100%' minW='300px' spacing={10} mx={10} my={10}>
				<VStack spacing={4}>
					<HStack spacing={0} display='flex' alignItems='center'>
						<Text fontSize='40px' fontWeight='bold'>
							Poll
						</Text>
						<Text fontSize='40px' fontWeight='bold' color='dark.200'>
							Hub
						</Text>
					</HStack>

					<Text
						minW='300px'
						maxW='650px'
						color='gray.50'
						fontFamily={"Arial"}
						fontSize='50px'
						textAlign='center'
						fontWeight='bold'>
						Sign up for the<span style={{ color: "#62f59a" }}>&nbsp;beta</span>!
					</Text>
					<Text minW='300px' maxW='650px' color='gray.300' fontFamily={"Arial"} fontSize='20px' textAlign='center'>
						PollHub is a <span style={{ color: "#62f59a" }}>real-time voting platform</span> where people can create and
						vote on polls with complete access to each's <span style={{ color: "#62f59a" }}>data and analytics</span>.
					</Text>
				</VStack>
				{!isSubmitted ? (
					<VStack w='100%'>
						<Formik
							initialValues={{ email: "" }}
							onSubmit={async (values, actions) => {
								let ipv4 = "";
								let ipv6 = "";

								try {
									const ipv4Res = await (await fetch("https://api.ipify.org?format=json")).json();
									ipv4 = ipv4Res.ip;

									const ipv6Res = await (await fetch("https://api64.ipify.org?format=json")).json();
									ipv6 = ipv6Res.ip;
								} catch (err) {}

								try {
									await addDoc(collection(db, "emails"), {
										email: values.email,
										ipv4: ipv4,
										ipv6: ipv6,
									});

									actions.setSubmitting(false);
									toast({
										title: "Submission Received!",
										description: "Thank you for your interest in PollHub!",
										status: "success",
										duration: 3000,
										position: "bottom-left",
										isClosable: true,
									});
									setIsSubmitted(true);
								} catch (err) {
									toast({
										title: "Something went wrong!",
										description: "We could not receive your submission. Please try again later.",
										status: "error",
										duration: 3000,
										position: "bottom-left",
										isClosable: true,
									});
								}
							}}>
							{(props) => (
								<Form style={{ width: "100%" }}>
									<Field validate={validate} name='email' type='email'>
										{({ field, form }: any) => (
											<FormControl isInvalid={form.errors.email && form.touched.email}>
												<FormLabel fontWeight='semibold' htmlFor='email' color='dark.200'>
													Email
												</FormLabel>
												<Input
													{...field}
													id='email'
													borderColor={"white"}
													_placeholder={{ color: "gray.300" }}
													_hover={{ borderColor: "dark.200" }}
													_focus={{ borderColor: "dark.200" }}
													placeholder={"Enter email for early access"}
												/>
												<FormErrorMessage>{form.errors.email}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
									<Button mt={4} loadingText={"Submitting"} isLoading={props.isSubmitting} type='submit'>
										Submit
									</Button>
								</Form>
							)}
						</Formik>
					</VStack>
				) : (
					<VStack spacing={10}>
						<div style={{ width: "200px" }} ref={animationContainer} />
						<div>
							<Text minW='300px' maxW='650px' color='gray.300' fontFamily={"Arial"} fontSize='20px' textAlign='center'>
								<span style={{ color: "#62f59a" }}>Thank you</span> for signing up for PollHub's beta! We are thrilled
								to have you on board! We will keep you updated by <span style={{ color: "#62f59a" }}>email</span>.
							</Text>
							<Text minW='300px' maxW='650px' color='dark.200' fontFamily={"Arial"} fontSize='20px' textAlign='center'>
								Coming soon...
							</Text>
						</div>
					</VStack>
				)}
			</VStack>
		</Flex>
	);
}

export default App;
