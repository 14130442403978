// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWyFpGADdaq9G3_zyWgCIraJgqFeY8pCo",
  authDomain: "pollhubmailinglist.firebaseapp.com",
  projectId: "pollhubmailinglist",
  storageBucket: "pollhubmailinglist.appspot.com",
  messagingSenderId: "676200477381",
  appId: "1:676200477381:web:86cefa0e87db6ff469f6ac",
  measurementId: "G-WGYG0LKRW9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export {analytics, db}